/* Navbar.css */
:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --text-color: white;
  }
  
  .navbar {
    background-color: var(--primary-color);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
  }
  
  .navbar-logo {
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
  }
  
  .nav-item {
    height: 60px;
  }
  
  .nav-links {
    color: var(--text-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    background-color: var(--secondary-color);
    transition: 0.3s;
  }
  
  /* Stil pentru hamburger menu pe mobile */
  .menu-icon {
    display: none;
    font-size: 1.8rem;
    color: var(--text-color);
    cursor: pointer;
  }
  
  /* Responsivitate */
  @media screen and (max-width: 768px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: -100%;
      width: 100%;
      height: 100vh;
      background-color: var(--primary-color);
      transition: left 0.3s ease;
      opacity: 0;
    }
  
    .nav-menu.active {
      left: 0;
      opacity: 1;
      transition: left 0.3s ease, opacity 0.3s ease;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .nav-links {
      padding: 2rem;
      width: 100%;
      text-align: center;
      display: block;
    }
  
    .menu-icon {
      display: block;
    }
  }
  