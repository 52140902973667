@import "./login-form.css";
@import "./navbar.css";
@import "./register.css";
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'token-verification.css';
@import './user-page.css';
@import './genAI.css';
:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --background-color: #f4f4f4;
    --text-color: #333;
    --font-family: 'Roboto', sans-serif;
    --spacing: 16px;
  }
  
  /* Reset pentru a elimina marginile implicite */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--background-color);
    line-height: 1.6;
    margin: 0;
  }
  
  /* Container general */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--spacing);
  }
  
  /* Stil pentru header */
  header {
    background: var(--primary-color);
    color: white;
    padding: var(--spacing) 0;
    text-align: center;
  }
  
  header h1 {
    font-size: 2.5rem;
    margin-bottom: var(--spacing);
  }
  
  /* Navigație */
  nav {
    display: flex;
    justify-content: center;
    gap: var(--spacing);
  }
  
  nav a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  nav a:hover {
    background-color: var(--secondary-color);
  }
  
  /* Layout pentru secțiunea principală */
  main {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
    padding: var(--spacing);
  }
  
  /* Stilizare secțiuni */
  section {
    background: white;
    padding: var(--spacing);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Grid */
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: var(--spacing);
  }
  
  /* Stil pentru butoane */
  button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  button:hover {
    background-color: var(--secondary-color);
  }
  
  /* Formular */
  form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
  }
  
  input, textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
  
  input:focus, textarea:focus {
    outline: none;
    border-color: var(--primary-color);
  }
  
  /* Footer */
  footer {
    background: var(--primary-color);
    color: white;
    text-align: center;
    padding: var(--spacing);
    margin-top: var(--spacing);
  }
  
  footer p {
    margin: 0;
  }
  