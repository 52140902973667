/* Stilizarea pentru containerul form-ului */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Asigură-te că este centrat vertical */
    background-color: #f0f0f0;
  }
  
  /* Stilizarea pentru form */
  .token-verification-container {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .token-verification-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .token-verification-container button {
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
  }
  
  .token-verification-container button:hover {
    background-color: #2980b9;
  }
  