/* RegisterComponent.css */
:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --text-color: #333;
    --background-color: #f4f4f4;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
  }
  
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--background-color);
  }
  
  .register-form {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .register-form h2 {
    margin-bottom: 20px;
    color: var(--primary-color);
  }
  
  .register-form input {
    width: 100%;
    padding: 12px 20px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .register-form .input-group {
    position: relative;
  }
  
  .register-form .input-group i {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: var(--primary-color);
    font-size: 1.2rem;
  }
  
  .register-form input {
    padding-left: 40px;
  }
  
  .register-form button {
    width: 100%;
    padding: 12px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .register-form button:hover {
    background-color: var(--secondary-color);
    transition: background-color 0.3s ease;
  }
  
  .register-form p {
    margin-top: 10px;
    font-size: 1rem;
    color: red;
  }
  