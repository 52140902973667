/* Stilurile pentru pagina de utilizatori */

.users-container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .users-container h1 {
    color: #343a40;
    font-size: 32px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
  }
  
  .users-table th, .users-table td {
    padding: 12px 15px;
    text-align: center;
  }
  
  .users-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #ddd;
  }
  
  .users-table td {
    border: 1px solid #ddd;
  }
  
  .users-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .users-table tr:hover {
    background-color: #e9ecef;
    transform: scale(1.01);
    transition: 0.3s ease;
  }
  
  .loading-message {
    color: #007bff;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .error-message {
    color: #dc3545;
    font-size: 18px;
    margin-top: 20px;
  }
  
  /* Media Queries pentru ecrane mai mici */
  @media (max-width: 1200px) {
    .users-container {
      padding: 10px;
      max-width: 100%;
    }
  
    .users-container h1 {
      font-size: 28px;
    }
  
    .users-table th, .users-table td {
      padding: 10px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 768px) {
    .users-container {
      padding: 10px;
      max-width: 100%;
    }
  
    .users-container h1 {
      font-size: 24px;
    }
  
    .users-table {
      font-size: 14px;
    }
  
    .users-table th, .users-table td {
      padding: 8px;
      font-size: 12px;
    }
  
    /* Transformăm tabelul în carduri pentru ecrane mici */
    .users-table, .users-table thead, .users-table tbody, .users-table th, .users-table td, .users-table tr {
      display: block;
    }
  
    .users-table tr {
      margin-bottom: 15px;
      background-color: white;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
  
    .users-table th {
      display: none;  /* Ascundem antetul */
    }
  
    .users-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    .users-table td:before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }
  }
  