@keyframes typing {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .typing-effect {
    display: inline-block;
    white-space: pre-wrap;  /* Permite textului să se spargă pe linii multiple */
    overflow: hidden;
    border-right: 2px solid rgba(0, 0, 0, 0.75); /* Cursul care clipește */
    animation: caret 1s steps(1) infinite;
  }
  
  .typing-effect span {
    opacity: 0;
    animation: typing 1s forwards;
    animation-delay: calc(var(--char-index) * 0.05s); /* Delay-ul pe baza indexului fiecărei litere */
  }
  
  @keyframes caret {
    50% {
      border-color: transparent;
    }
  }
  
  .typing-effect p {
    font-family: monospace;
    font-size: 18px;
    display: inline-block;
    white-space: pre-wrap;  /* Pentru a permite textului să fie responsive */
    word-wrap: break-word;  /* Spargem cuvintele care sunt prea lungi */
    max-width: 100%;        /* Limităm lățimea pentru a preveni ieșirea din ecran */
  }
  
  /* Media queries pentru a face animația responsive */
  @media (max-width: 768px) {
    .typing-effect p {
      font-size: 14px;
    }
  
    textarea {
      width: 90%;
      font-size: 14px;
    }
  
    button {
      font-size: 14px;
      padding: 8px 12px;
    }
  }
  